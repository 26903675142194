import type { Routes } from '@angular/router';
import { BankCardCreateComponent } from './pages/bank-card-create/bank-card-create.component';
import { BankCardDetailsComponent } from './pages/bank-card-details/bank-card-details.component';
import { BankCardListComponent } from './pages/bank-card-list/bank-card-list.component';
import { BudgetCorrectionCreateComponent } from './pages/budget-correction-create/budget-correction-create.component';
import { BudgetCorrectionDetailsComponent } from './pages/budget-correction-details/budget-correction-details.component';
import { BudgetCorrectionListComponent } from './pages/budget-correction-list/budget-correction-list.component';
import { EduBudgetImportComponent } from './pages/edu-budget-import/edu-budget-import.component';
import { EduBudgetComponent } from './pages/edu-budget/edu-budget.component';
import { EduExpenseCategoryListComponent } from './pages/edu-expense-category-list/edu-expense-category-list.component';
import { EduExpenseCreateComponent } from './pages/edu-expense-create/edu-expense-create.component';
import { EduExpenseDetailsComponent } from './pages/edu-expense-details/edu-expense-details.component';
import { EduExpenseListComponent } from './pages/edu-expense-list/edu-expense-list.component';
import { EduExpensePurposeListComponent } from './pages/edu-expense-purpose-list/edu-expense-purpose-list.component';
import { EventCreateComponent } from './pages/event-create/event-create.component';
import { EventDetailsComponent } from './pages/event-details/event-details.component';
import { EventListComponent } from './pages/event-list/event-list.component';
import type { EventListRouteData } from './pages/event-list/event-list.models';
import { FunBudgetComponent } from './pages/fun-budget/fun-budget.component';
import { GroupCreateComponent } from './pages/group-create/group-create.component';
import { GroupDetailsComponent } from './pages/group-details/group-details.component';
import { GroupListComponent } from './pages/group-list/group-list.component';
import { InventoryItemCreateBulkComponent } from './pages/inventory-item-create-bulk/inventory-item-create-bulk.component';
import { InventoryItemCreateComponent } from './pages/inventory-item-create/inventory-item-create.component';
import { InventoryItemDetailsComponent } from './pages/inventory-item-details/inventory-item-details.component';
import { InventoryItemEditComponent } from './pages/inventory-item-edit/inventory-item-edit.component';
import { InventoryItemListComponent } from './pages/inventory-item-list/inventory-item-list.component';
import { InventoryItemLocationListComponent } from './pages/inventory-item-location-list/inventory-item-location-list.component';
import { InventoryItemManufacturerListComponent } from './pages/inventory-item-manufacturer-list/inventory-item-manufacturer-list.component';
import { InventoryItemOwnerListComponent } from './pages/inventory-item-owner-list/inventory-item-owner-list.component';
import { InventoryItemServiceCreateComponent } from './pages/inventory-item-service-create/inventory-item-service-create.component';
import { InventoryItemServiceDetailsComponent } from './pages/inventory-item-service-details/inventory-item-service-details.component';
import { InventoryItemServiceEditComponent } from './pages/inventory-item-service-edit/inventory-item-service-edit.component';
import { InventoryItemServiceListComponent } from './pages/inventory-item-service-list/inventory-item-service-list.component';
import { InventoryItemStatusListComponent } from './pages/inventory-item-status-list/inventory-item-status-list.component';
import { InventoryItemTypeListComponent } from './pages/inventory-item-type-list/inventory-item-type-list.component';
import { MyInventoryItemsComponent } from './pages/my-inventory-items/my-inventory-items.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { PersonalTravelBudgetComponent } from './pages/personal-travel-budget/personal-travel-budget.component';
import { UserCreateComponent } from './pages/user-create/user-create.component';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { VendorListComponent } from './pages/vendor-list/vendor-list.component';
import { VpnPhoneNumberCreateComponent } from './pages/vpn-phone-number-create/vpn-phone-number-create.component';
import { VpnPhoneNumberDetailsComponent } from './pages/vpn-phone-number-details/vpn-phone-number-details.component';
import { VpnPhoneNumberListComponent } from './pages/vpn-phone-number-list/vpn-phone-number-list.component';
import { VpnSubscriptionTypeCreateComponent } from './pages/vpn-subscription-type-create/vpn-subscription-type-create.component';
import { VpnSubscriptionTypeListComponent } from './pages/vpn-subscription-type-list/vpn-subscription-type-list.component';
import { appTitle } from './shared/constants/app-title.constant';
import {
  bankCardsAdminGuard,
  eduEquAdminGuard,
  funGamesAdminGuard,
  inventoryAdminGuard,
  peopleAdminGuard,
  phoneNumbersAdminGuard,
  remoteEmployeeGuard,
  superAdminGuard,
} from './shared/guards/admin.guard';
import { authGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview',
  },
  {
    path: '',
    canActivateChild: [authGuard],
    children: [
      {
        title: `${appTitle} - Overview`,
        path: 'overview',
        component: OverviewComponent,
      },
      {
        title: `${appTitle} - Fun & Games Budget`,
        path: 'fun-budget',
        component: FunBudgetComponent,
      },
      {
        title: `${appTitle} - My Events`,
        path: 'my-events',
        component: EventListComponent,
      },
      {
        title: `${appTitle} - My Personal Travel Budget`,
        path: 'personal-travel-budget',
        component: PersonalTravelBudgetComponent,
        canActivate: [remoteEmployeeGuard],
      },
      {
        title: `${appTitle} - All Events`,
        path: 'events',
        component: EventListComponent,
        canActivate: [funGamesAdminGuard],
        data: (() => {
          const data: EventListRouteData = {
            filter: 'all',
          };
          return data;
        })(),
      },
      {
        title: `${appTitle} - Create Event`,
        path: 'events/create',
        component: EventCreateComponent,
        canActivate: [funGamesAdminGuard],
      },
      {
        title: `${appTitle} - Event Details`,
        path: 'events/:id',
        component: EventDetailsComponent,
      },
      {
        title: `${appTitle} - Budget Corrections`,
        path: 'budget-corrections',
        component: BudgetCorrectionListComponent,
        canActivate: [funGamesAdminGuard],
      },
      {
        title: `${appTitle} - Create Budget Correction`,
        path: 'budget-corrections/create',
        component: BudgetCorrectionCreateComponent,
        canActivate: [funGamesAdminGuard],
      },
      {
        title: `${appTitle} - Budget Correction Details`,
        path: 'budget-corrections/:id',
        component: BudgetCorrectionDetailsComponent,
        canActivate: [funGamesAdminGuard],
      },
      {
        title: `${appTitle} - Vendors`,
        path: 'vendors',
        component: VendorListComponent,
        canActivate: [funGamesAdminGuard],
      },
      {
        title: `${appTitle} - Edu & Equ Budget`,
        path: 'edu-budget',
        component: EduBudgetComponent,
      },
      {
        title: `${appTitle} - Edu & Equ Transactions`,
        path: 'edu-transactions',
        component: EduExpenseListComponent,
        canActivate: [eduEquAdminGuard],
      },
      {
        title: `${appTitle} - Create Edu & Equ Transaction`,
        path: 'edu-transactions/create',
        component: EduExpenseCreateComponent,
        canActivate: [eduEquAdminGuard],
      },
      {
        title: `${appTitle} - Import Edu Budget`,
        path: 'edu-transactions/import-edu-budget',
        component: EduBudgetImportComponent,
        canActivate: [superAdminGuard],
      },
      {
        title: `${appTitle} - Edu & Equ Transaction Details`,
        path: 'edu-transactions/:id',
        component: EduExpenseDetailsComponent,
        canActivate: [eduEquAdminGuard],
      },
      {
        title: `${appTitle} - Edu & Equ Categories`,
        path: 'edu-categories',
        component: EduExpenseCategoryListComponent,
        canActivate: [eduEquAdminGuard],
      },
      {
        title: `${appTitle} - Edu & Equ Purposes`,
        path: 'edu-purposes',
        component: EduExpensePurposeListComponent,
        canActivate: [eduEquAdminGuard],
      },
      {
        title: `${appTitle} - My Inventory Items`,
        path: 'my-inventory-items',
        component: MyInventoryItemsComponent,
      },
      {
        title: `${appTitle} - Inventory Items`,
        path: 'inventory-items',
        component: InventoryItemListComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Create Inventory Item`,
        path: 'inventory-items/create',
        component: InventoryItemCreateComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Bulk Create Inventory Items`,
        path: 'inventory-items/create/bulk',
        component: InventoryItemCreateBulkComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Edit Inventory Item`,
        path: 'inventory-items/:id/edit',
        component: InventoryItemEditComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Inventory Item Details`,
        path: 'inventory-items/:id',
        component: InventoryItemDetailsComponent,
      },
      {
        title: `${appTitle} - Inventory Item Services`,
        path: 'inventory-item-services',
        component: InventoryItemServiceListComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Create Inventory Item Service`,
        path: 'inventory-item-services/create',
        component: InventoryItemServiceCreateComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Inventory Item Service Details`,
        path: 'inventory-item-services/:id',
        component: InventoryItemServiceDetailsComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Edit Inventory Item Service`,
        path: 'inventory-item-services/:id/edit',
        component: InventoryItemServiceEditComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Inventory Locations`,
        path: 'inventory-locations',
        component: InventoryItemLocationListComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Inventory Types`,
        path: 'inventory-types',
        component: InventoryItemTypeListComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Inventory Owners`,
        path: 'inventory-owners',
        component: InventoryItemOwnerListComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Inventory Statuses`,
        path: 'inventory-statuses',
        component: InventoryItemStatusListComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Inventory Manufacturers`,
        path: 'inventory-manufacturers',
        component: InventoryItemManufacturerListComponent,
        canActivate: [inventoryAdminGuard],
      },
      {
        title: `${appTitle} - Groups`,
        path: 'groups',
        component: GroupListComponent,
        canActivate: [peopleAdminGuard],
      },
      {
        title: `${appTitle} - Create Group`,
        path: 'groups/create',
        component: GroupCreateComponent,
        canActivate: [peopleAdminGuard],
      },
      {
        title: `${appTitle} - Group Details`,
        path: 'groups/:id',
        component: GroupDetailsComponent,
      },
      {
        title: `${appTitle} - Members`,
        path: 'users',
        component: UserListComponent,
        canActivate: [peopleAdminGuard],
      },
      {
        title: `${appTitle} - Add Member`,
        path: 'users/create',
        component: UserCreateComponent,
        canActivate: [peopleAdminGuard],
      },
      {
        title: `${appTitle} - Member Details`,
        path: 'users/:id',
        component: UserDetailsComponent,
        canActivate: [peopleAdminGuard],
      },
      {
        title: `${appTitle} - VPN Phone Numbers`,
        path: 'vpn-phone-numbers',
        component: VpnPhoneNumberListComponent,
        canActivate: [phoneNumbersAdminGuard],
      },
      {
        title: `${appTitle} - Create VPN Phone Number`,
        path: 'vpn-phone-numbers/create',
        component: VpnPhoneNumberCreateComponent,
        canActivate: [phoneNumbersAdminGuard],
      },
      {
        title: `${appTitle} - VPN Phone Number Details`,
        path: 'vpn-phone-numbers/:id',
        component: VpnPhoneNumberDetailsComponent,
        canActivate: [phoneNumbersAdminGuard],
      },
      {
        title: `${appTitle} - VPN Subscription Types`,
        path: 'vpn-subscription-types',
        component: VpnSubscriptionTypeListComponent,
        canActivate: [phoneNumbersAdminGuard],
      },
      {
        title: `${appTitle} - Create VPN Subscription Type`,
        path: 'vpn-subscription-types/create',
        component: VpnSubscriptionTypeCreateComponent,
        canActivate: [phoneNumbersAdminGuard],
      },
      {
        title: `${appTitle} - Bank Cards`,
        path: 'bank-cards',
        component: BankCardListComponent,
        canActivate: [bankCardsAdminGuard],
      },
      {
        title: `${appTitle} - Create Bank Card`,
        path: 'bank-cards/create',
        component: BankCardCreateComponent,
        canActivate: [bankCardsAdminGuard],
      },
      {
        title: `${appTitle} - Bank Card Details`,
        path: 'bank-cards/:id',
        component: BankCardDetailsComponent,
        canActivate: [bankCardsAdminGuard],
      },
      {
        title: `${appTitle} - Salary Calculator`,
        path: 'salary-calculator',
        loadComponent: () =>
          import('./pages/salary-calculator/salary-calculator.component').then(
            (m) => m.SalaryCalculatorComponent,
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'overview',
  },
];
