import type { CurrencyPipe, DatePipe } from '@angular/common';
import type { TooltipOptions } from 'chart.js';
import { Decimal } from 'decimal.js';
import { PdhrColors, PdsColors } from '../../shared/constants/colors.constants';
import { PdsFonts } from '../../shared/constants/fonts.constants';
import { PdsMotion } from '../../shared/constants/motion.constants';
import {
  EduTransactionLogType,
  type EduTransactionLogRecord,
} from '../../shared/models/api/edu-transaction-log.model';
import {
  FunTransactionLogType,
  type FunTransactionLogRecord,
} from '../../shared/models/api/fun-transaction-log.model';
import type { EduChart, FunChart, FunChartMonth } from './overview.models';

export function getBaseTooltip<T extends 'bar' | 'line' | 'doughnut' | 'sankey'>(
  theme: 'light' | 'dark' | 'auto',
): Partial<TooltipOptions<T>> {
  const baseTooltip: Partial<TooltipOptions<T>> = {
    animation: {
      duration: PdsMotion.DurationModerate,
    },
    backgroundColor:
      theme === 'dark' ? PdsColors.ThemeDarkBackgroundSurface : PdsColors.ThemeLightBackgroundBase,
    bodyColor: theme === 'dark' ? PdsColors.ThemeDarkPrimary : PdsColors.ThemeLightPrimary,
    bodyFont: {
      family: PdsFonts.family,
      lineHeight: PdsFonts.lineHeight,
      size: PdsFonts.size.xxSmallPx,
      weight: PdsFonts.weigth.regular,
    },
    borderColor:
      theme === 'dark' ? PdsColors.ThemeDarkContrastMedium : PdsColors.ThemeLightContrastMedium,
    borderWidth: 1,
    boxPadding: 4,
    boxHeight: 16,
    boxWidth: 16,
    caretPadding: 4,
    caretSize: 8,
    cornerRadius: 4,
    padding: 8,
    titleColor: theme === 'dark' ? PdsColors.ThemeDarkPrimary : PdsColors.ThemeLightPrimary,
    titleFont: {
      family: PdsFonts.family,
      lineHeight: PdsFonts.lineHeight,
      size: PdsFonts.size.smallPx,
      weight: PdsFonts.weigth.semiBold,
    },
  };
  return baseTooltip;
}

export function getFunChart(
  records: FunTransactionLogRecord[],
  datePipe: DatePipe,
  currencyPipe: CurrencyPipe,
  theme: 'light' | 'dark' | 'auto',
): FunChart {
  const monthlyData = buildMonths().reverse();

  records.forEach((record) => {
    const monthData = monthlyData.find((m) => record.dateCreated.startsWith(m.date));
    if (!monthData) return;

    if (record.type === FunTransactionLogType.ALLOWANCE) {
      monthData.allowance = getAbsRound(record.budgetAfter);
    }
    if (record.type === FunTransactionLogType.COST) {
      monthData.spent += getAbsRound(record.budgetChange);
      monthData.extraSpent += getAbsRound(record.amount + record.budgetChange);
    }
  });

  const chart: FunChart = {
    data: {
      labels: monthlyData.map((month) => datePipe.transform(month.date, 'MMM')!),
      datasets: [
        {
          data: monthlyData.map((month) => month.allowance),
          backgroundColor: PdhrColors.Lime,
          borderColor: PdhrColors.Lime,
          type: 'line',
        },
        {
          data: monthlyData.map((month) => month.spent),
          backgroundColor: PdhrColors.Water,
        },
        {
          data: monthlyData.map((month) => month.extraSpent),
          backgroundColor: PdhrColors.Orange,
        },
      ],
    },
    options: {
      animation: {
        duration: PdsMotion.DurationVeryLong,
      },
      responsive: true,
      maintainAspectRatio: false,
      hover: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          border: {
            width: 1,
            color:
              theme === 'dark'
                ? PdsColors.ThemeDarkContrastMedium
                : PdsColors.ThemeLightContrastMedium,
          },
          ticks: {
            color:
              theme === 'dark'
                ? PdsColors.ThemeDarkContrastMedium
                : PdsColors.ThemeLightContrastMedium,
            font: {
              family: PdsFonts.family,
              lineHeight: PdsFonts.lineHeight,
              size: PdsFonts.size.xxSmallPx,
              weight: PdsFonts.weigth.regular,
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
          border: {
            width: 1,
            color:
              theme === 'dark'
                ? PdsColors.ThemeDarkContrastMedium
                : PdsColors.ThemeLightContrastMedium,
          },
          ticks: {
            color:
              theme === 'dark'
                ? PdsColors.ThemeDarkContrastMedium
                : PdsColors.ThemeLightContrastMedium,
            font: {
              family: PdsFonts.family,
              lineHeight: PdsFonts.lineHeight,
              size: PdsFonts.size.xxSmallPx,
              weight: PdsFonts.weigth.regular,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          ...getBaseTooltip<'bar' | 'line'>(theme),
          callbacks: {
            title: (context) => {
              return context[0]?.label ?? '';
            },
            label: (context) => {
              const month = monthlyData[context.dataIndex];
              if (!month) return;

              if (context.datasetIndex === 0)
                return `Total monthly top-up: ${currencyPipe.transform(month.allowance, 'EUR')}`;
              if (context.datasetIndex === 1)
                return `Budget deduction: ${currencyPipe.transform(month.spent, 'EUR')}`;
              if (context.datasetIndex === 2)
                return `Extra spent: ${currencyPipe.transform(month.extraSpent, 'EUR')}`;
              return '';
            },
          },
          mode: 'index',
          intersect: false,
        },
      },
    },
  };
  return chart;
}

export function getEduChart(
  expenses: EduTransactionLogRecord[],
  theme: 'light' | 'dark' | 'auto',
): EduChart {
  const isEmpty = !expenses.length;
  const edu = expenses
    .filter((expense) => expense.type === EduTransactionLogType.EDU_EXPENSE)
    .reduce((acc, expense) => acc + expense.amount, 0);
  const equ = expenses
    .filter((expense) => expense.type === EduTransactionLogType.EQU_EXPENSE)
    .reduce((acc, expense) => acc + expense.amount, 0);
  const total = edu + equ;
  const eduPercentage = getPercentage(edu, total);
  const equPercentage = getPercentage(equ, total);

  const chart: EduChart = {
    data: {
      datasets: [
        {
          data: isEmpty ? [100] : [eduPercentage, equPercentage],
          backgroundColor: isEmpty
            ? [theme === 'dark' ? PdsColors.ThemeDarkContrastLow : PdsColors.ThemeLightContrastLow]
            : [PdhrColors.Plum, PdhrColors.Mint],
          spacing: eduPercentage && equPercentage ? 8 : 0,
          borderWidth: 0,
        },
      ],
    },
    options: {
      animation: {
        duration: PdsMotion.DurationVeryLong,
      },
      cutout: '80%',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          ...getBaseTooltip<'doughnut'>(theme),
          enabled: !isEmpty,
          callbacks: {
            title: (context) => {
              if (context[0].dataIndex === 0) return 'Education';
              if (context[0].dataIndex === 1) return 'Equipment';
              return;
            },
            label: (context) => `${context.formattedValue}%`,
          },
        },
      },
    },
  };
  return chart;
}

function buildMonths(): FunChartMonth[] {
  return Array.from({ length: 12 }, () => undefined).map((_, index) => {
    const today = new Date();
    today.setMonth(today.getMonth() - index);
    const json = today.toJSON();

    const chartMonth: FunChartMonth = {
      date: json.substring(0, json.indexOf('-', json.indexOf('-') + 1)),
      allowance: 0,
      spent: 0,
      extraSpent: 0,
    };
    return chartMonth;
  });
}

function getAbsRound(amount: number): number {
  if (!amount) return 0;

  const num = new Decimal(amount).abs();
  const round = num.times(100).round().dividedBy(100);
  return round.toNumber();
}

function getPercentage(amount: number, total: number): number {
  if (!amount || !total) return 0;

  const num = new Decimal(amount).abs();
  const tot = new Decimal(total).abs();
  const percent = num.dividedBy(tot).times(100);
  const round = percent.round();
  return round.toNumber();
}
